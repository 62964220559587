/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Stage } from "../../../types/Types.tsx";
import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useApiRequest from "../../../services/RestClient.jsx";
import { Formik, Form, Field } from "formik";
import { convertToBase64 } from "../../../utils/helpers.tsx";
import { ProjectContext } from "../../../context/ProjectContext.jsx";

interface StageFormDialogProps {
  stage: Stage;
  onRefresh: () => Promise<void> | void;
}

export const StageFormDialog: React.FC<StageFormDialogProps> = ({
  stage,
  onRefresh,
}) => {
  const { currentProjectId } = React.useContext(ProjectContext);
  const { read, create, update } = useApiRequest();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [attachments, setAttachments] = React.useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = React.useState<any>(null);

  const [isUploaded, setUploaded] = React.useState(false);

  const [stageStatus, setStageStatus] = React.useState<
    "review" | "approve" | null
  >(
    stage.stage_status === "review" || stage.stage_status === "approve"
      ? stage.stage_status
      : null
  );

  const handleSubmit = async (values) => {
    setLoading(true);

    // Separate 'files' and the rest of the values
    const { files, attachment_ids, ...updateValues } = values;
    updateValues["job_id"] = Number(currentProjectId);
    // Check if 'files' key exists and has files
    if (files && files.length > 0) {
      const uploadPromises = files.map((file, index) => {
        const payload = {
          name: file.filename,
          file: file.data.split(",")[1],
          attachment_type: "client",
          stage_id: values.id,
        };

        return create("triplet.stage.attachment", payload);
      });

      // Wait for all file uploads to complete
      try {
        await Promise.all(uploadPromises);
      } catch (error) {
        return;
      }
    }

    // Update the stage after file uploads (if any) are complete
    try {
      await update("triplet.job.stage", values.id, {
        response: values.response,
        stage_status: stageStatus ?? false,
      });
      onRefresh();
    } catch (error) {}
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false); // Close the dialog for reasons other than backdrop click and escape key
    }
    // If reason is 'backdropClick' or 'escapeKeyDown', do nothing to prevent closing
  };

  const handleApprove = async (values) => {
    setLoading(true);
    try {
      await update("triplet.job.stage", values.id, {
        stage_status: "approve",
      });
      onRefresh();
      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          <Button
            sx={{ fontWeight: "bolder" }}
            onClick={() => handleDownload(params.row.file, params.row.name)}
            startIcon={<CloudDownloadIcon />} // Replace with an appropriate icon
          >
            Download
          </Button>
        </React.Fragment>
      ),
    },
  ];

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const files = Array.from(event.target.files as FileList);
    const base64Promises = files.map((file: File) => convertToBase64(file));
    setUploadedFiles(files);
    try {
      const base64Files = await Promise.all(base64Promises);
      setFieldValue("files", base64Files);

      setUploaded(true);
    } catch (error) {}
  };

  const handleDownload = (base64Data: string, filename: string) => {
    // Decode Base64 string to binary data.
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the binary data.
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Create a URL for the Blob and trigger the download.
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename; // Set the filename for the download.
    document.body.appendChild(link); // Add the link to the document.
    link.click(); // Trigger the download.

    // Cleanup: remove the link and revoke the URL.
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  React.useEffect(() => {
    if (open === true && stage.attachment_ids.length > 0) {
      try {
        read("triplet.stage.attachment", "[]", stage.attachment_ids).then(
          (value) => {
            setAttachments(value);
          }
        );
      } catch (error) {}
    }
  }, [open]);

  return (
    <React.Fragment>
      <Button
        sx={{ fontWeight: "bolder" }}
        onClick={handleClickOpen}
        startIcon={<VisibilityIcon />}
      >
        Preview
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <Formik
          enableReinitialize
          initialValues={stage ?? {}}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, dirty }) => (
            <Form className="d-flex flex-column w-100 gap-2">
              <DialogTitle
                justifyContent="space-between"
                className="d-flex w-100"
              >
                <Typography>{stage.name ?? "UNKNOWN STAGE NAME"}</Typography>
                <Chip label={stage?.stage_type[1] ?? "Unknown"} />
              </DialogTitle>
              <DialogContent>
                <Stack direction="column" gap={4}>
                  <DialogContentText component={Stack} gap={1}>
                    <Typography variant="subtitle1" color="black">
                      Stage Description
                    </Typography>
                    <Typography variant="body2">
                      {stage?.description}
                    </Typography>
                  </DialogContentText>

                  <Box component={Stack} gap={1} sx={{ height: "300px" }}>
                    <Typography variant="subtitle1">
                      Attachments Table
                    </Typography>
                    <DataGrid
                      columns={columns}
                      rows={attachments ?? []}
                      hideFooter
                      autoHeight={false}
                    />
                  </Box>
                  {stageStatus === "review" ? (
                    <Stack direction="column" gap={2}>
                      <Typography variant="subtitle1">
                        Your Review Notes
                      </Typography>

                      <Field
                        as={TextField}
                        name="response"
                        multiline
                        rows={4}
                        label="Your Notes"
                        placeholder="Please Write any notes you have for this review stage."
                      />
                      {isUploaded === false ? (
                        <React.Fragment>
                          <Typography variant="body2">
                            Please upload all helpful files that you have to
                            describe more about your review request
                          </Typography>
                          <label
                            htmlFor="file-upload"
                            style={{
                              border: "1px solid #ccc",
                              display: "inline-block",
                              padding: "6px 12px",
                              cursor: "pointer",
                            }}
                          >
                            Upload Files
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleFileUpload(event, setFieldValue)
                            }
                          />
                        </React.Fragment>
                      ) : (
                        <div className="d-flex flex-row gap-3 align-items-end">
                          {uploadedFiles.map((file, index) => (
                            <div key={index}>
                              {file.type.startsWith("image/") && (
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                  style={{ width: "100px", height: "auto" }}
                                />
                              )}
                              <Typography variant="body2">
                                {file.name}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      )}
                    </Stack>
                  ) : null}
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => setOpen(false)}
                  disabled={loading}
                >
                  {stageStatus === "approve" ? "Close" : "Cancel"}
                </Button>

                {stageStatus === "review" ? (
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!dirty || loading === true}
                    // onClick={() => handleSubmit(values)}
                  >
                    {loading === true ? (
                      <CircularProgress size={14} thickness={10} />
                    ) : null}
                    Submit
                  </Button>
                ) : stageStatus === "approve" ? null : (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      disabled={loading}
                      onClick={() => setStageStatus("review")}
                    >
                      Request Review
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleApprove(values)}
                      disabled={loading}
                    >
                      {loading === true ? (
                        <CircularProgress size={14} thickness={10} />
                      ) : null}
                      Approve Stage
                    </Button>
                  </React.Fragment>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

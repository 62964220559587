/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from "react";

import useApiRequest from "../services/RestClient";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { searchRead, update } = useApiRequest();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState({});

  const updateProfile = async (updatedData) => {
    try {
      setLoading(true);
      await update(
        "res.partner",
        updatedData?.id,
        updatedData,
        "Your Profile Updated Successfully"
      );
      setData(updatedData);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadCountries = async () => {
    try {
      const values = await searchRead("res.country", "[]");
      setCountries(values?.records);
    } catch (error) {
      console.error("Error loading countries:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = localStorage.getItem("uid");
        await loadCountries();
        const domain = `[[['user_ids', 'in', ${uid}]]]`;
        const neededFields = [
          "name",
          "id",
          "mobile",
          "website",
          "title",
          "email",
          "city",
          "country_id",
          "street",
          "field_of_business",
          "vat",
          "phone",
          "image_1920",
          "basic_job_ids",
        ];
        const value = await searchRead("res.partner", domain, neededFields);
        if (value?.records?.length > 0) {
          const profileData = value.records[0];
          profileData.country_id = profileData.country_id[0] ?? "";
          setData(profileData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <ProfileContext.Provider
      value={{ loading, data, updateProfile, countries }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

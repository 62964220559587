import React from "react";
import { Container, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { useRouteError } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const error = useRouteError();
  let errorMessage = "Unknown Error";
  if (typeof error === "object" && error !== null) {
    if ("message" in error) {
      errorMessage = (error as { message: string }).message;
    } else if ("statusText" in error) {
      errorMessage = (error as { statusText: string }).statusText;
    }
  }
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ErrorOutlineIcon
        style={{ fontSize: 60, marginBottom: 20, color: "primary.main" }}
      />
      <Typography variant="h4" color="primary" gutterBottom>
        Oops!
      </Typography>
      <Typography variant="body1" paragraph>
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant="body2" style={{ fontStyle: "italic" }}>
        {errorMessage}
      </Typography>
      <Button
        className="mt-2"
        variant="contained"
        color="primary"
        startIcon={<HomeIcon />}
        onClick={goHome}
      >
        Go Home
      </Button>
    </Container>
  );
};

export default ErrorPage;

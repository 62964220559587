import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import { TextField, Grid, Button } from "@mui/material";
import * as Yup from "yup";
import { ProjectContext } from "../../../context/ProjectContext";
import MultiSelectWithChips from "../../../components/fields/MultiSelectChips";

const ProjectDetailsForm = () => {
  const { updateProject, countries, projectDetailsMap, setRefresh } =
    useContext(ProjectContext);

  const validationSchema = Yup.object({
    brand_name: Yup.string(),
    brand_website: Yup.string(),
    brand_description: Yup.string(),
    company_location: Yup.string(),
    country_ids: Yup.array(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await updateProject(values);
      resetForm({ values: values });
      setRefresh(true);
    } catch (e) {}
  };

  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={projectDetailsMap}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, values, setFieldValue, resetForm }) => (
          <Form className="w-100 d-flex align-items-end flex-column gap-3">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="brand_name"
                  label="Brand Name"
                  fullWidth
                  placeholder="Brand Name"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="brand_website"
                  label="Brand Website"
                  fullWidth
                  placeholder="Brand Website"
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectWithChips
                  label="Country"
                  name="country_ids"
                  nestedName={null}
                  choices={countries}
                  values={values}
                  setFieldValue={setFieldValue}
                  onChange={null}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="company_location"
                  label="Location"
                  fullWidth
                  placeholder="Company Location"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="brand_description"
                  label="Description"
                  fullWidth
                  multiline
                  rows="3"
                  placeholder="Brand Description"
                />
              </Grid>
            </Grid>

            <Button disabled={!dirty} type="submit" variant="contained">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProjectDetailsForm;

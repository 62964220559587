import { useCallback } from "react";
import useApiRequest from "../services/RestClient";

function useStepOperations(
  currentProjectId,
  modelName,
  dispatch,
  state,
  Types
) {
  const { read, create, update, deleteRecord } = useApiRequest();

  const readAll = useCallback(
    async (projectSteps) => {
      if (!Array.isArray(projectSteps) || projectSteps.length === 0) {
        return;
      }

      try {
        const result = await read(modelName, "[]", projectSteps);
        dispatch({ type: Types.ReadAll, payload: result });
      } catch (error) {
        dispatch({
          type: Types.ReadAll,
          payload: error.message || "An error occurred",
        });
      }
    },
    [modelName, read, dispatch, Types]
  );

  const createRecord = useCallback(
    async (index, values) => {
      const payload = { ...values, job_id: currentProjectId };

      try {
        const response = await create(modelName, payload);
        dispatch({
          type: Types.CreateRecord,
          payload: { index, id: response.id, value: values },
        });
      } catch (error) {
        console.error("Failed to create record:", error);
      }
    },
    [modelName, currentProjectId, create, dispatch, Types]
  );

  const updateRecord = useCallback(
    async (index, values) => {
      const payload = { ...values, job_id: Number(currentProjectId) };

      try {
        const response = await update(modelName, values.id, payload);
        dispatch({
          type: Types.SubmitUpdate,
          payload: { index, recordId: response.id, value: values },
        });
      } catch (error) {
        console.error("Failed to update record:", error);
      }
    },
    [modelName, currentProjectId, update, dispatch, Types]
  );

  const handleDelete = useCallback(
    async (id, index) => {
      if (id) {
        try {
          await deleteRecord(modelName, id);
          dispatch({ type: Types.DeleteRecord, payload: { index } });
        } catch (error) {
          console.error("Failed to delete record:", error);
        }
      } else {
        dispatch({ type: Types.DeleteRecord, payload: { index } });
      }
    },
    [modelName, deleteRecord, dispatch, Types]
  );

  const dispatchCreateEdit = useCallback(
    async (index, value) => {
      if ("id" in state.data[index]) {
        dispatch({ type: Types.SubmitUpdate, payload: {} });
        await updateRecord(index, value[index]);
      } else {
        await createRecord(index, value[index]);
      }
    },
    [state.data, createRecord, updateRecord, dispatch, Types]
  );

  const changeRecord = async (values, index) => {
    dispatch({
      type: Types.ChangeRecord,
      payload: { values: values, id: index },
    });
  };

  return {
    readAll,
    createRecord,
    updateRecord,
    handleDelete,
    dispatchCreateEdit,
    changeRecord,
  };
}

export default useStepOperations;

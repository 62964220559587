import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignUpBox from "./components/SignUpBox";
import logo from "../../assets/logo_white.png";
const SignUpPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  });

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return !!token;
  };

  return (
    <div
      className="container-primary-hue d-flex flex-row gap-4 p-4 align-items-center justify-content-evenly vh-100"
      style={{ backgroundColor: "#010803" }}
    >
      <SignUpBox />
      <img src={logo} height={100} alt="motion_go" />
    </div>
  );
};

export default SignUpPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { TextField, Button, IconButton, Grid, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectContext } from "../../../context/ProjectContext";
import { State, Types } from "../../../types/Types.tsx";
import { useCustomReducerHook } from "../../../types/Reducer.tsx";
import useObjectiveOperations from "../../../types/Operations.tsx";

interface Objective {
  id: number | null;
  name: string;
}

const ObjectivesForm: React.FC = () => {
  const { currentProjectId, projectData, setRefresh } =
    useContext(ProjectContext);
  const initialState: State<Objective> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };

  const { state, dispatch } = useCustomReducerHook<Objective>(initialState);
  const modelName = "triplet.job.objective"; // Define the model name here

  const { readAll, handleDelete, dispatchCreateEdit, changeRecord } =
    useObjectiveOperations(currentProjectId, modelName, dispatch, state, Types);

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.project_objectives);
      if (projectData.project_objectives.length === 0) {
        dispatch({
          type: Types.AddAnother,
          payload: {
            values: [],
            newItem: { name: "" },
          },
        });
      }
    }
  }, [currentProjectId, projectData, readAll]);

  const handleSubmit = (values) => {};

  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={state.data ?? []}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className="w-100">
            <Grid container spacing={2}>
              {values?.map((step, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={11}>
                    <Field
                      name={`${index}.name`}
                      as={TextField}
                      label="Objective"
                      fullWidth
                      onKeyUp={() => changeRecord(values, index)}
                    />
                  </Grid>
                  <Grid item xs={1} className="d-flex align-items-center">
                    <Button
                      variant="contained"
                      size="small"
                      disabled={!state.dirtyIndexes.includes(index)}
                      onClick={async () => {
                        await dispatchCreateEdit(index, values);
                        setRefresh(true);
                      }}
                    >
                      Submit
                    </Button>
                    <IconButton
                      onClick={async () => {
                        await handleDelete(step.id, index);
                        setRefresh(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
              <Stack
                marginTop={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    dispatch({
                      type: Types.AddAnother,
                      payload: { values: values, newItem: { name: "" } },
                    })
                  }
                >
                  ADD OBJECTIVE
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ObjectivesForm;

export enum Types {
  ReadAll,
  DeleteRecord,
  CreateRecord,
  ChangeRecord,
  SubmitUpdate,
  AddAnother,
}
export interface State<T> {
  data: T[] | null;
  error: string | null;
  loading: boolean;
  dirtyIndexes: number[];
}

export interface Action {
  type:
    | Types.ReadAll
    | Types.CreateRecord
    | Types.DeleteRecord
    | Types.ChangeRecord
    | Types.SubmitUpdate
    | Types.AddAnother;
  payload: any;
}

export interface Stage {
  id: number;
  name: string | boolean;
  attachment_ids: [];
  stage_type: string | boolean;
  description: string;
  job_id: [];
  stage_status: string | boolean;
  response: string | false;
}

export const genderOptions = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
  { id: "both", name: "Both" },
];

export const videoDurationOptions = [
  { value: "10s", label: "10 seconds" },
  { value: "30s", label: "30 seconds" },
  { value: "60s", label: "60 seconds" },
  { value: "other", label: "Other" },
];


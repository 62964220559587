import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/Error/Error.tsx";
import LoginPage from "./pages/Login/Login.tsx";
import HomePageWithProvider from "./pages/Home/Home.tsx";
import MainLayout from "./components/layout/MainLayout.tsx";
import ProjectPage from "./pages/Project/Project.tsx";
import SignUpPage from "./pages/Signup/SignUp";
import ProfilePage from "./pages/Profile/Profile.tsx";
import StagePage from "./pages/Stage/Stage.tsx";
import ProjectLayoutWithProvider from "./components/layout/ProjectLayout.tsx";
const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <SignUpPage />,
  },
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/project/:projectId",
        element: <ProjectLayoutWithProvider />,
        children: [
          {
            index: true,
            element: <ProjectPage />,
          },
          {
            path: "stages",
            element: <StagePage />,
          },
        ],
      },
      {
        index: true,
        element: <HomePageWithProvider />,
      },

      {
        path: "profile",
        element: <ProfilePage />,
      },
    ],
  },
]);

export default router;

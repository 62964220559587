export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export function convertToBase64(
  file: File
): Promise<{ filename: string; data: string }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const result = reader.result as string;
      // You can still split the result and get the base64 data if needed
      // const base64String = result.split(",")[1];
      const base64String = result;

      // Resolve both the filename and the base64 data
      resolve({ filename: file.name, data: base64String });
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export const openBase64InNewTab = (
  base64: string,
  mimeType: string = "image/jpeg"
) => {
  // Create the data URL with the specified MIME type
  const dataUrl = `base64,${base64}`;

  // Open a new window or tab with the data URL
  const newWindow = window.open();
  if (newWindow) {
    newWindow.document.write(
      `<img src="${dataUrl}" alt="Attachment" style="max-width:100%;height:auto;">`
    );
  }
};

/**
 * Checks if the attachment is valid.
 * @param attachment - The attachment to check, can be string, null, or boolean.
 * @returns boolean - Returns true if the attachment is valid, otherwise false.
 */
export function isAttachmentValid(
  attachment: string | null | boolean
): boolean {
  return attachment !== null && attachment !== false && attachment !== "";
}

export const generateRandomString = (length = 8) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const calculateProgress = (data) => {
  if (data !== null) {
    let percentage = 0;

    // Existing checks
    if (data.language_ids && data.language_ids.length > 0) percentage += 10;
    if (data.project_links && data.project_links.length > 0) percentage += 10;
    if (data.project_steps && data.project_steps.length > 0) percentage += 10;
    if (data.project_objectives && data.project_objectives.length > 0)
      percentage += 10;
    if (data.documents && data.documents.length > 0) percentage += 10;
    if (data.target_audiences && data.target_audiences.length > 0)
      percentage += 10;
    if (data.similar_templates && data.similar_templates.length > 0)
      percentage += 10;
    if (data.project_competitor && data.project_competitor.length > 0)
      percentage += 5;
    if (data.similar_video_style && data.similar_video_style.length > 0)
      percentage += 5;
    if (
      data.technical_characteristics &&
      data.technical_characteristics.length > 0
    )
      percentage += 10;

    // New checks
    if (data.project_description && data.project_description.trim() !== "")
      percentage += 4;
    if (data.brand_name && data.brand_name.trim() !== "") percentage += 3;
    if (data.brand_description && data.brand_description.trim() !== "")
      percentage += 2;
    if (data.name && data.name.trim() !== "") percentage += 1;
    return percentage;
  }
  return 0;
};
/**
 * Triggers a download of a file from a base64 data URL.
 *
 * @param {string} base64DataUrl - The base64 data URL of the file, including the MIME type.
 * @param {string} fileName - The name of the file to download.
 */
export function downloadFromBase64DataUrl(base64DataUrl, fileName) {
  try {
    // Extract MIME type and base64 string from the data URL
    const [prefix, base64String] = base64DataUrl.split(",");
    const mimeType = prefix.match(/:([^;]+);/)[1];

    // Convert base64 to raw binary data held in a string
    const byteString = atob(base64String);

    // Write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob from the ArrayBuffer
    const blob = new Blob([ab], { type: mimeType });

    // Create a link element for the download
    const link = document.createElement("a");

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);
    link.href = url;

    // Set the download attribute of the link to set the file name
    link.download = fileName;

    // Append the link to the body, click it, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file from base64 data URL:", error);
  }
}

/**
 * Downloads a file from the given URL.
 *
 * @param {string} url - The URL of the file to download.
 * @param {string} filename - The desired filename for the downloaded file.
 */
export function downloadFile(url, filename) {
  // Check if URL and filename are provided
  if (!url || !filename) {
    console.error("URL and filename are required to download the file.");
    return;
  }

  // Create a temporary anchor element
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;

  // Append the anchor to the body, click it to trigger the download, and then remove it
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

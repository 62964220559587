import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import icon1 from "../../assets/icon1.png";
import homeIcon from "../../assets/home-2.png";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ProjectProvider } from "../../context/ProjectContext";
import {
  Box,
  Tabs,
  Tab,
  Stack,
  Breadcrumbs,
  Typography,
  Avatar,
} from "@mui/material";
const ProjectLayout = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const getTabIndex = (path: string) => {
      if (path.includes("/stages")) return 1;
      return 0;
    };

    setValue(getTabIndex(location.pathname));
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate(`/project/${projectId}`);
    } else if (newValue === 1) {
      navigate(`/project/${projectId}/stages`);
    }
  };
  return (
    <main className="d-flex flex-column vhd-100 w-100 bg-light">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingX={1}
        >
          <Stack padding="0 10px" direction="row" alignItems="center">
            <a href="/" className="navbar-brand" style={{ width: "260px" }}>
              <img src={logo} alt="Motion GO" height={40} />
            </a>
            <Breadcrumbs aria-label="breadcrumb">
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#424242",
                }}
              >
                <img
                  src={homeIcon}
                  alt="Motion GO"
                  height={20}
                  className="me-2"
                />
                Home
              </NavLink>

              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="primary"
              >
                <img src={icon1} alt="Motion GO" height={20} className="me-2" />
                Project Details
              </Typography>
            </Breadcrumbs>
          </Stack>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Details" />
            <Tab label="Stages" disabled={projectId === "new"} />
          </Tabs>
          <NavLink to="/profile">
            <Avatar />
          </NavLink>
        </Stack>
      </Box>

      <Outlet />
    </main>
  );
};

const ProjectLayoutWithProvider = () => (
  <ProjectProvider>
    <ProjectLayout />
  </ProjectProvider>
);
export default ProjectLayoutWithProvider;

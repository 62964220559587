import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";

import { gatewayURL, serverURL } from "../../services/RestClient";
import NetworkErrorScreen from "../../pages/Error/NetworkError.tsx";

const MainLayout: React.FC<{ onNetworkError: (error: boolean) => void }> = ({
  onNetworkError,
}) => {
  const navigate = useNavigate();

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem("uid");
    return !!token;
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }

    const fetchData = async () => {
      try {
        await axios.get(`${gatewayURL}check_server_status`, {
          params: {
            url: serverURL,
          },
        });

        onNetworkError(false);
      } catch (error) {
        onNetworkError(true);
      }
    };

    fetchData();
  }, [navigate, onNetworkError]);

  return (
    <main className="d-flex vh-100">
      <Outlet />
    </main>
  );
};

const MainComponent: React.FC = () => {
  const [isNetworkError, setIsNetworkError] = useState<boolean>(false);

  const handleNetworkError = (error: boolean) => {
    setIsNetworkError(error);
  };

  return isNetworkError ? (
    <NetworkErrorScreen />
  ) : (
    // <ProjectProvider>
    <MainLayout onNetworkError={handleNetworkError} />
    // </ProjectProvider>
  );
};

export default MainComponent;

import "./App.css";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import muiTheme from "./utils/MuiTheme";
import router from "./routes";
function App() {
  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={2000} preventDuplicate>
        <SnackbarProvider maxSnack={3} autoHideDuration={2000} preventDuplicate>
          <ThemeProvider theme={muiTheme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </SnackbarProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;

import React from "react";
import { Field } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

interface FormikMuiTextFieldProps extends Omit<TextFieldProps, "name" | "as"> {
  name: string;
  onKeyUp?: () => void;
}

const CustomTextField: React.FC<FormikMuiTextFieldProps> = ({
  name,
  label,
  variant = "filled",
  fullWidth = true,
  multiline,
  rows = 1,
  onKeyUp,
  ...otherProps
}) => {
  return (
    <Field
      name={name}
      as={TextField}
      label={label}
      onKeyUp={onKeyUp}
      variant={variant}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      {...otherProps}
    />
  );
};

export default CustomTextField;

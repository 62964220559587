import { Button, ButtonGroup, Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import backgroundImage from "../../../assets/Background.png";
import { FaUser } from "react-icons/fa";
import { Avatar } from "@mui/material";
import { BsBasketFill } from "react-icons/bs";
export const StatCard = ({ value, text, caption, icon, color }) => {
  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: color,
  };

  return (
    <div className={`col-xxl-3 col-md-6 mb-4`}>
      <div className={`card card-raised text-white`} style={cardStyle}>
        <div className="card-body px-4">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="me-2">
              <div className="display-5 text-white">{value}</div>
              <div className="card-text" style={{ fontWeight: "bolder" }}>
                {text}
              </div>
            </div>
            <Avatar
              sx={{
                background: "transparent",
                width: "80px",
                height: "80px",
                border: "2px solid white",
              }}
              className="customAvatar"
            >
              {icon}
            </Avatar>
          </div>
          <div className="card-text">
            <div className="d-inline-flex align-items-center">
              <div className="caption">{caption}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductDetailsCard = ({ children, title, chip, chipColor }) => {
  return (
    // <div className={`col-xxl-6 col-md-12 mb-3`}>
    <div className={`card card-raised text-dark shadow-sm mb-3`}>
      <div className="card-body px-4">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="me-2 w-100">
            <div
              className="text-dark d-flex justify-content-between align-items-center"
              style={{ fontWeight: "bold" }}
            >
              {title}
              {chip != null ? <Chip label={chip} color={chipColor} /> : null}
            </div>
            <hr />
          </div>
        </div>
        <div className="card-text">
          <div className="d-inline-flex align-items-center w-100">
            <div className="caption container-fluid p-0">{children}</div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export const OrderCard = ({ order }) => {
  return (
    // <div className={`col-xxl-6 col-md-12 mb-3`}>
    <div className={`card card-raised text-dark shadow-sm mb-3`}>
      <div className="card-body px-4">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="me-2 w-100">
            <div
              className="text-dark d-flex justify-content-between align-items-center"
              style={{ fontWeight: "bold" }}
            >
              Order #{order.order_id ?? order.id ?? "Unknown"}
              {order["status"] != null ? (
                <Chip label={order["status"]} />
              ) : null}
            </div>
            <hr />
          </div>
        </div>
        <div className="card-text">
          <div className="d-inline-flex align-items-center w-100">
            <div className="caption container-fluid p-0">
              <p className="d-flex gap-2 align-items-center">
                <BsBasketFill />
                {order.order_name ?? "Unknown"}
              </p>
              <p className="d-flex gap-2 align-items-center">
                <FaUser />
                {order.customer_name}
              </p>

              <p> Created At: {new Date(order.created_at).toLocaleString()}</p>
              <hr />
              <div className="d-flex justify-content-between">
                <ButtonGroup>
                  <IconButton variant="outlined">
                    <DeleteIcon />
                  </IconButton>
                  {order.status !== "COMPLETED" ? (
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  ) : null}
                </ButtonGroup>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                >
                  View Details
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

import React, { useContext } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { ProjectContext } from "../../../context/ProjectContext";

const TemplatesForm = () => {
  const { templates, templatesMap, updateProject, setRefresh } =
    useContext(ProjectContext);

  const validationSchema = Yup.object({
    selectedTemplateIds: Yup.array().of(Yup.number()),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // TODO: Fix remove all choices case
      await updateProject({ similar_templates: values.selectedTemplateIds });
      resetForm({ values: values });
      setRefresh(true);
    } catch (e) {}
  };

  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={templatesMap}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, dirty, resetForm }) => (
          <Form className="w-100">
            <Grid container spacing={2}>
              {templates?.map((template) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={template.id}>
                  <Card>
                    <CardMedia
                      component="img"
                      src={`data:image/png;base64, ${template.attachment}`}
                      alt={template.name}
                    />
                    <CardContent>
                      <Typography variant="h5">{template.name}</Typography>
                      <Typography variant="body2">
                        {template.description}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.selectedTemplateIds.includes(
                              template.id
                            )}
                            onChange={(e) => {
                              const newSelectedIds =
                                values.selectedTemplateIds.includes(template.id)
                                  ? values.selectedTemplateIds.filter(
                                      (id) => id !== template.id
                                    )
                                  : [
                                      ...values.selectedTemplateIds,
                                      template.id,
                                    ];
                              setFieldValue(
                                "selectedTemplateIds",
                                newSelectedIds
                              );
                            }}
                          />
                        }
                        label="Select"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <div className="d-flex justify-content-end mt-4 w-100">
              <Button disabled={!dirty} type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TemplatesForm;

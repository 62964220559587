import React, { createContext, useState, useEffect } from "react";
import useApiRequest from "../services/RestClient";

export const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
  const { searchRead } = useApiRequest();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);

  const getUserData = async () => {
    try {
      const uid = localStorage.getItem("uid");
      const userDataResponse = await searchRead(
        "res.partner",
        `[[['user_ids', 'in', ${uid}]]]`,
        ["name", "id", "email"]
      );

      if (userDataResponse?.records[0]) {
        setUserData(userDataResponse?.records[0]);
        localStorage.setItem("user_id", userDataResponse.records[0].id);

        const dataResponse = await searchRead(
          "triplet.job.video_design",
          `[[['partner_id', '=', ${userDataResponse.records[0].id}]]]`
        );
        setData(dataResponse?.records);
      }
    } catch (error) {
      // Handle errors here, e.g., show an error message
      console.error("Failed to fetch data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserData();
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <HomeContext.Provider value={{ loading, data, userData }}>
      {children}
    </HomeContext.Provider>
  );
};

import axios from "axios";
import { useSnackbar } from "notistack";

// Odoo Server URL and Database Name
export const gatewayURL = "http://triplets.techleara.net:8000/";
export const serverURL = "http://triplets.techleara.net:8069/";
export const dbName = "triplets";

// export const gatewayURL = "http://127.0.0.1:8000/";
// export const serverURL = "http://localhost:10017/";
// export const dbName = "mg1";

export const portalManagerUsername = "portal.manager@techleara.net";
export const portalManagerPW = "Pass@1234";

const axiosInstance = axios.create({
  baseURL: gatewayURL,
});

axiosInstance.interceptors.request.use((config) => {
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export const useApiRequest = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleApiError = (error) => {
    return enqueueSnackbar(
      error.response?.data?.detail || "Something went wrong",
      { variant: "error" }
    );
  };

  const authenticate = async (username, password) => {
    try {
      const response = await axiosInstance.post("/authenticate", {
        url: serverURL,
        db_name: dbName,
        username: username,
        password: password,
      });
      const responseData = response.data;
      return responseData;
    } catch (error) {
      handleApiError(error);
      return Promise.reject(error);
    }
  };

  const createPortalUser = async (userDetails) => {
    try {
      userDetails["portal_manager_username"] = portalManagerUsername;
      userDetails["portal_manager_password"] = portalManagerPW;
      userDetails["odoo_url"] = serverURL;
      userDetails["db_name"] = dbName;

      const response = await axiosInstance.post(
        "/create_portal_user",
        userDetails
      );
      const responseData = response.data;
      enqueueSnackbar("Portal User Created Successfully", {
        variant: "success",
      });
      return responseData;
    } catch (error) {
      handleApiError(error);
      return Promise.reject(error);
    }
  };

  const searchRead = async (modelName, domain, fields) => {
    try {
      const uid = localStorage.getItem("uid");
      const pw = localStorage.getItem("pw");
      const response = await axiosInstance.get("/search_read", {
        params: {
          url: serverURL,
          db_name: dbName,
          uid: uid,
          password: pw,
          odoo_model_name: modelName,
          domain: domain, // Ensure domain is a string
          fields: fields ?? [],
        },
        paramsSerializer: (params) => {
          // This function serializes the parameters in a way that your Python server expects
          return Object.keys(params)
            .map((key) => {
              if (Array.isArray(params[key])) {
                // For arrays, repeat the key for each value
                return params[key]
                  .map(
                    (value) =>
                      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                  )
                  .join("&");
              }
              // For other types, just encode key and value
              return `${encodeURIComponent(key)}=${encodeURIComponent(
                params[key]
              )}`;
            })
            .join("&");
        },
      });
      const responseData = response.data;
      return responseData;
    } catch (error) {
      handleApiError(error);
      return Promise.reject(error);
    }
  };

  const read = async (modelName, domain, idsList) => {
    try {
      const uid = localStorage.getItem("uid");
      const pw = localStorage.getItem("pw");
      const response = await axiosInstance.get("/read", {
        params: {
          url: serverURL,
          db_name: dbName,
          uid: uid,
          password: pw,
          odoo_model_name: modelName,
          ids: idsList,
          domain: domain,
        },
        paramsSerializer: (params) => {
          // This function serializes the parameters in a way that your Python server expects
          return Object.keys(params)
            .map((key) => {
              if (Array.isArray(params[key])) {
                // For arrays, repeat the key for each value
                return params[key]
                  .map(
                    (value) =>
                      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                  )
                  .join("&");
              }
              // For other types, just encode key and value
              return `${encodeURIComponent(key)}=${encodeURIComponent(
                params[key]
              )}`;
            })
            .join("&");
        },
      });
      const responseData = response.data;
      return responseData;
    } catch (error) {
      handleApiError(error);
      return Promise.reject(error);
    }
  };

  const create = async (modelName, payload, msg) => {
    const uid = localStorage.getItem("uid");
    const pw = localStorage.getItem("pw");
    try {
      const response = await axiosInstance.post("/create", {
        url: serverURL,
        db_name: dbName,
        uid: uid,
        password: pw,
        odoo_model_name: modelName,
        data: payload,
      });
      const responseData = response.data;
      enqueueSnackbar(msg ?? `Record Created Successfully`, {
        variant: "success",
      });
      return responseData;
    } catch (error) {
      return handleApiError(error);
    }
  };

  const update = async (modelName, recordId, payload, msg) => {
    const uid = localStorage.getItem("uid");
    const pw = localStorage.getItem("pw");
    try {
      const response = await axiosInstance.put("/update", {
        url: serverURL,
        db_name: dbName,
        uid: uid,
        password: pw,
        odoo_model_name: modelName,
        data: payload,
        record_id: recordId,
      });
      const responseData = response.data;
      enqueueSnackbar(msg ?? `Record Updated Successfully`, {
        variant: "success",
      });
      return responseData;
    } catch (error) {
      return handleApiError(error);
    }
  };

  const deleteRecord = async (modelName, recordId, msg) => {
    const uid = localStorage.getItem("uid");
    const pw = localStorage.getItem("pw");
    try {
      const response = await axiosInstance.delete("/delete", {
        params: {
          url: serverURL,
          db_name: dbName,
          uid: uid,
          password: pw,
          model_name: modelName,
          id: recordId,
        },
      });
      const responseData = response.data;
      enqueueSnackbar(msg ?? `Record Deleted Successfully`, {
        variant: "success",
      });
      return responseData;
    } catch (error) {
      return handleApiError(error);
    }
  };

  return {
    authenticate,
    createPortalUser,
    searchRead,
    create,
    update,
    read,
    deleteRecord,
  };
};

export default useApiRequest;

import { Fragment, useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";

import logo from "../../assets/logo.png";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicInfoForm from "./components/BasicInfoForm.tsx";
import ProjectDetailsForm from "./components/ProjectDetailsForm.tsx";
import MediaChannelsForm from "./components/MediaChannelsForm.tsx";
import ObjectivesForm from "./components/ObjectivesForm.tsx";
import LanguagesForm from "./components/LanguagesForm.tsx";
import StepsForm from "./components/StepsForm.tsx";
import TemplatesForm from "./components/TemplatesForm.jsx";
import TargetAudiencesForm from "./components/TargetAudiencesForm.tsx";
import RelatedDocumentsForm from "./components/DocumentsForm.tsx";
import TechnicalCharacteristicsForm from "./components/TechnicalCharacteristicsForm.tsx";
import SimilarCompetitorsForm from "./components/SimilarCompetitorsForm.tsx";
import Fab from "@mui/material/Fab";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { ProjectContext } from "../../context/ProjectContext.jsx";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { useParams } from "react-router-dom";
import LoadingKit from "../../components/loading/index.jsx";
import React from "react";
import { Box, Card, CircularProgress } from "@mui/material";
import StatusChip from "../../components/chips/StatusChip.tsx";
import { calculateProgress } from "../../utils/helpers.tsx";

const ProjectPage: React.FC = () => {
  const [index, setIndex] = useState(0);
  const handleSidebarClick = (tabIndex) => {
    setIndex(tabIndex);
  };

  const handleAccordionChange = (tabIndex) => (_, isExpanded) => {
    if (isExpanded) {
      setIndex(tabIndex);
    }
  };

  const { projectId } = useParams();
  const {
    loading,
    currentProjectId,
    setCurrentProjectId,
    projectData,
    updateStatus,
  } = useContext(ProjectContext);

  useEffect(() => {
    if (projectId && projectId !== currentProjectId) {
      setCurrentProjectId(projectId);
    }
  }, [currentProjectId, projectId, setCurrentProjectId]);

  if (loading === true) {
    return (
      <div className="d-flex h-100 w-100 align-items-center">
        <LoadingKit />
      </div>
    );
  }
  return (
    <div className="row w-100 h-100">
      <div
        id="steps-scroll-spy"
        className="col-2 p-3 d-flex flex-column bg-light border-end border-1 border-grey "
        style={{
          width: "280px",
        }}
      >
        <ul className="nav nav-pills flex-column mb-auto">
          {projectTabs.map((tab) => (
            <li key={tab.index}>
              <a
                href={"#" + tab.href}
                onClick={() => handleSidebarClick(tab.index)}
                className={`custom-nav-item ${
                  index === tab.index ? "active" : ""
                }`}
              >
                <Fragment>
                  {projectData !== null &&
                  ((Array.isArray(projectData[tab.param]) &&
                    projectData[tab.param].length > 0) ||
                    (!Array.isArray(projectData[tab.param]) &&
                      projectData[tab.param] !== false)) ? (
                    <AssignmentTurnedInIcon fontSize="small" />
                  ) : (
                    <AssignmentTurnedInOutlinedIcon fontSize="small" />
                  )}
                </Fragment>

                {tab.name}
              </a>
            </li>
          ))}
          <Card
            className="p-4 m-4"
            sx={{
              background: "#d62639",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "20px",
            }}
          >
            <Box
              position="relative"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress
                variant="determinate"
                thickness={7}
                value={100}
                sx={{
                  color: "#F59094",
                  zIndex: 1,
                }}
              />
              <CircularProgress
                variant="determinate"
                thickness={7}
                value={calculateProgress(projectData)} // Your dynamic progress value
                sx={{
                  color: "white", // This sets the filled color
                  position: "absolute",
                  zIndex: 2,
                }}
              />
              <div></div>
            </Box>

            <Typography
              variant="subtitle1"
              textAlign="center"
              fontWeight="bolder"
            >
              Progress
            </Typography>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bolder"
              className="d-flex flex-row"
            >
              {calculateProgress(projectData)}
              <Typography variant="h6">%</Typography>
            </Typography>
          </Card>
        </ul>
        {/* <ProgressDisplay percentage={calculateProgress(projectData)} /> */}
        {/* <hr /> */}
        <Box className="d-flex justify-content-center w-100">
          <Fab
            variant="extended"
            color="secondary"
            onClick={() => updateStatus()}
            disabled={projectData?.status === "submitted"}
          >
            <DoneAllIcon sx={{ mr: 1 }} />
            Submit For Review
          </Fab>
        </Box>
      </div>

      <div className="col text-black h-100 p-4" style={{ overflowY: "scroll" }}>
        {projectTabs.map((tab) => (
          <Accordion
            key={tab.index}
            sx={{
              background: "transparent",
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
              "&:not(:last-child)": {
                borderBottom: "1px solid #ccc",
              },
              "&:last-child": {
                borderBottom: "1px solid #ccc",
              },
            }}
            id={tab.href}
            expanded={
              (tab.index === 0 &&
                (currentProjectId === null || currentProjectId === "new")) ||
              (index === tab.index &&
                currentProjectId !== null &&
                currentProjectId !== "new")
            }
            onChange={handleAccordionChange(tab.index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="me-auto" id={tab.href}>
                {tab.name}
              </Typography>

              {tab.index === 0 ? (
                <div className="me-2">
                  <StatusChip status={projectData?.status} />
                </div>
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              {tab.component ?? <Typography> Details in Here</Typography>}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default ProjectPage;

const projectTabs = [
  {
    index: 0,
    name: "Basic Information",
    href: "basic-information",
    param: "projectName",
    component: <BasicInfoForm />,
  },
  {
    index: 1,
    name: "Project Details",
    href: "project-details",
    param: "brand_name",
    component: <ProjectDetailsForm />,
  },
  {
    index: 2,
    name: "Social Media Links",
    href: "social-media-links",
    param: "project_links",
    component: <MediaChannelsForm />,
  },
  {
    index: 3,
    name: "Project Objectives",
    href: "objectives",
    param: "project_objectives",
    component: <ObjectivesForm />,
  },
  {
    index: 4,
    name: "Project Steps",
    href: "steps",
    param: "project_steps",
    component: <StepsForm />,
  },
  {
    index: 5,
    name: "Languages",
    href: "languages",
    param: "language_ids",
    component: <LanguagesForm />,
  },
  {
    index: 6,
    name: "Templates",
    href: "patterns",
    param: "similar_templates",

    component: <TemplatesForm />,
  },
  {
    index: 7,
    name: "Target Audiences",
    href: "target-audiences",
    param: "target_audiences",
    component: <TargetAudiencesForm />,
  },
  {
    index: 8,
    name: "Documents",
    href: "documents",
    param: "documents",
    component: <RelatedDocumentsForm />,
  },

  {
    index: 9,
    name: "Duration and Voiceover",
    href: "duration-voiceover",
    param: "technical_characteristics",
    component: <TechnicalCharacteristicsForm />,
  },
  {
    index: 10,
    name: "Similar Competitors",
    href: "similar-competitors",
    param: "project_competitor",
    component: <SimilarCompetitorsForm />,
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  IconButton,
  Grid,
  Stack,
  Divider,
  Typography,
  CircularProgress,
  Chip,
} from "@mui/material";

import CloudDoneIcon from "@mui/icons-material/CloudDone";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark"; // For "Logo"
import PaletteIcon from "@mui/icons-material/Palette"; // For "Visual Identity"
import BusinessIcon from "@mui/icons-material/Business"; // For "Company Profile"
import AnimationIcon from "@mui/icons-material/Animation"; // For "Logo Animation"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // For "Other"

import { BsFolderPlus } from "react-icons/bs";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectContext } from "../../../context/ProjectContext";
import useStepOperations from "../../../types/Operations.tsx";
import { useCustomReducerHook } from "../../../types/Reducer.tsx";
import { State, Types } from "../../../types/Types.tsx";
import {
  convertToBase64,
  downloadFromBase64DataUrl,
} from "../../../utils/helpers.tsx";
interface Document {
  id: number | null;
  name: string;
  category: string | null;
  description: string | null;
  attachment: string | null;
  attachment_url: string | null;
}
const RelatedDocumentsForm = () => {
  const { currentProjectId, projectData, setRefresh } =
    useContext(ProjectContext);
  const initialState: State<Document> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };
  const [uploading, setUploading] = useState(false);
  const { state, dispatch } = useCustomReducerHook<Document>(initialState);
  const modelName = "triplet.document";

  const { readAll, handleDelete, dispatchCreateEdit, changeRecord } =
    useStepOperations(currentProjectId, modelName, dispatch, state, Types);

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.documents);

      if (projectData.documents.length === 0) {
        const defaultItems = documentCategories
          .slice(0, 3)
          .map((category) => createDefaultItem(category.id));

        const newItem = createDefaultItem("logo_animation");

        dispatch({
          type: Types.AddAnother,
          payload: {
            values: [...defaultItems],
            newItem,
          },
        });
      }
    }
  }, [currentProjectId, projectData, readAll]);

  const handleSubmit = (values) => {};

  const updateValuesAndSubmit = useCallback(
    async (index, values) => {
      setUploading(true);

      var newValues = { ...values };

      console.log(newValues[index]["attachment"]);

      // Old Base to restore the mime type of the file
      var oldBase = newValues[index]["attachment"].data;
      newValues[index]["attachment"] =
        newValues[index]["attachment"].data.split(",")[1];

      console.log(newValues);
      await dispatchCreateEdit(index, newValues).then((_) =>
        setUploading(false)
      );
      setRefresh(true);

      newValues[index]["attachment"] = oldBase;
    },
    [dispatchCreateEdit, setRefresh]
  );

  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={state.data ?? []}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="w-100">
            <Grid container spacing={2}>
              {values.map((doc, index) => {
                return (
                  <React.Fragment key={index}>
                    {/* <Grid item xs={12}>
                      <Typography variant="body2">Document Type</Typography>
                      <ButtonGroup fullWidth>
                        {documentCategories.map((option) => {
                          const isSelected = doc.category === option.id;
                          return (
                            <Button
                              key={option.id}
                              variant={isSelected ? "contained" : "outlined"}
                              onClick={() => {
                                const newCategory = isSelected ? "" : option.id;
                                setFieldValue(`${index}.category`, newCategory);
                                changeRecord(values, index);
                              }}
                              startIcon={option.icon} // Replace IconComponent with the actual icon for the category
                            >
                              {option.name}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Stack direction="row" gap={1}>
                        {doc.name && (
                          <Typography variant="body2" fontWeight="bold">
                            Document Name
                          </Typography>
                        )}
                        {doc.name && (
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            color="grey"
                          >
                            {doc.name}{" "}
                            {!state.dirtyIndexes.includes(index) ? (
                              <CloudDoneIcon
                                sx={{ width: "20px", marginRight: "4px" }}
                                color="success"
                              />
                            ) : null}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" gap={1}>
                        {doc.category && doc.category !== "other" && (
                          <Typography variant="body2" fontWeight="bold">
                            Document Type
                          </Typography>
                        )}
                        {doc.category && (
                          <Typography variant="subtitle2" color="grey">
                            {documentCategories.find(
                              (category) => category.id === doc.category
                            )?.message || ""}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      {documentCategories.map((option) => {
                        const isSelected = doc.category === option.id;
                        return (
                          <Chip
                            key={option.id}
                            label={option.name}
                            onClick={() => {
                              const newCategory = isSelected ? "" : option.id;
                              setFieldValue(`${index}.category`, newCategory);
                              changeRecord(values, index);
                            }}
                            icon={option.icon}
                            color={isSelected ? "primary" : "default"}
                            style={{ marginRight: 4 }}
                          />
                        );
                      })}
                    </Grid>

                    {/* 
                    <Grid item xs={12}>
                      {doc.category && (
                        <Typography variant="subtitle2">
                          {documentCategories.find(
                            (category) => category.id === doc.category
                          )?.message || ""}
                        </Typography>
                      )}
                    </Grid> */}

                    <Grid item xs={12}>
                      <div className="position-relative">
                        {values[index].attachment === null ||
                        values[index].attachment === "" ? (
                          <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            startIcon={<BsFolderPlus />}
                          >
                            Upload Attachment
                          </Button>
                        ) : (
                          <Button
                            startIcon={<BsFolderPlus />}
                            variant="contained"
                            fullWidth
                            size="large"
                          >
                            Change Attachment
                          </Button>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          id={`${index}.name`}
                          onChange={async (event) => {
                            if (
                              event.currentTarget.files &&
                              event.currentTarget.files[0]
                            ) {
                              const file = event.currentTarget.files[0];
                              try {
                                const base64String = await convertToBase64(
                                  event.currentTarget.files[0]
                                );

                                setFieldValue(
                                  `${index}.attachment`,
                                  base64String
                                );
                                setFieldValue(`${index}.name`, file.name);
                                changeRecord(values, index);
                              } catch (error) {}
                            }
                          }}
                        />

                        <label
                          htmlFor={`${index}.name`}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      gap={1}
                      className="d-flex align-items-center"
                    >
                      <Button
                        variant="contained"
                        size="small"
                        disabled={!state.dirtyIndexes.includes(index)}
                        onClick={() => updateValuesAndSubmit(index, values)}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={
                          (!values[index].attachment_url ||
                            values[index].attachment_url === "") &&
                          values[index].attachment !== null &&
                          values[index].attachment === ""
                        }
                        onClick={() => {
                          if (
                            values[index].attachment_url === "" ||
                            values[index].attachment_url === null
                          ) {
                            downloadFromBase64DataUrl(
                              values[index].attachment,
                              values[index].name
                            );
                          } else {
                            const url = values[index].attachment_url;
                            window.open(url, "_blank");
                          }
                        }}
                      >
                        Preview
                      </Button>
                      <IconButton
                        onClick={async () => {
                          await handleDelete(doc.id, index);
                          setRefresh(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>

                    {/* Divider */}
                    {index < values.length - 1 && (
                      <Grid item xs={12}>
                        <Divider sx={{ background: "grey" }} />
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
              <Stack
                marginTop={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                gap={2}
              >
                {uploading === true ? <CircularProgress /> : null}
                <Button
                  variant="contained"
                  disabled={uploading}
                  onClick={() => {
                    dispatch({
                      type: Types.AddAnother,
                      payload: {
                        values: values,
                        newItem: {
                          name: "",
                          category: "",
                          description: "",
                          attachment: "",
                          attachment_url: "",
                        },
                      },
                    });
                  }}
                >
                  {uploading ? "LOADING" : "ADD DOCUMENT"}
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RelatedDocumentsForm;

const documentCategories = [
  {
    id: "logo",
    name: "Logo",
    icon: <BrandingWatermarkIcon />,
    message: "Please upload logo as vector or high resolution image",
  },
  {
    id: "visual_identity",
    name: "Visual Identity",
    icon: <PaletteIcon />,
    message: "Please upload visual identity as vector or pdf",
  },
  {
    id: "company_profile",
    name: "Company Profile",
    icon: <BusinessIcon />,
    message: "Please upload company profile as pdf",
  },
  {
    id: "logo_animation",
    name: "Logo Animation",
    icon: <AnimationIcon />,
    message: "Please upload logo animation as mov or mp4",
  },
  {
    id: "other",
    name: "Other",
    icon: <MoreHorizIcon />,
    message: "",
  },
];

const createDefaultItem = (category) => ({
  name: "",
  category,
  description: "",
  attachment: "",
  attachment_url: "",
});

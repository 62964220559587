import React from "react";
import { Container, Typography, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import WifiOffIcon from "@mui/icons-material/WifiOff";

const NetworkErrorScreen: React.FC = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <WifiOffIcon
        style={{ fontSize: 60, marginBottom: 20, color: "primary.main" }}
      />
      <Typography variant="h4" color="primary" gutterBottom>
        Network Error
      </Typography>
      <Typography variant="body1" paragraph>
        Cannot reach the server. Please check your internet connection.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={refreshPage}
      >
        Refresh
      </Button>
    </Container>
  );
};

export default NetworkErrorScreen;

import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#D62639",
      light: "#0C7ED5",
      dark: "#400B11",
      veryLight: "#D0E0F9",
      extraLight: "#EBEFFE",
      custom: "#8FADF1",
    },
    secondary: {
      main: "#400B11",
      dark: "#D62639",
    },
    info: {
      main: "#F89700",
    },
    actionIcons: {
      view: "#4698BC",
      edit: "#54374F",
      delete: "#695F5F",
    },
    statusChip: {
      valid: "#A0C97F",
      invalid: "#E5787E",
      other: "#75B4D2",
      blue: "#75B4D2",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: "16px",
          "& $notchedOutline": {
            borderColor: "#424242",
          },
          "&:hover $notchedOutline": {
            borderColor: "#94a3b8",
          },
          "&.Mui-focused $notchedOutline": {
            borderColor: "#D62639",
          },
        },
        input: {
          backgroundColor: "white",
          borderRadius: "16px",
          paddingLeft: "10px",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: "16px",
        },
      },
    },
  },
});

export default muiTheme;

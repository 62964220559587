import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, Grid, Button } from "@mui/material";
import * as Yup from "yup";
import { ProjectContext } from "../../../context/ProjectContext";

const BasicInfoForm = () => {
  const {
    createProject,
    updateProject,
    currentProjectId,
    basicInfoMap,
    setRefresh,
  } = useContext(ProjectContext);

  const validationSchema = Yup.object({
    name: Yup.string().required("Project Name is required"),
    project_description: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (currentProjectId === "new" || currentProjectId === null) {
      await createProject(values);
    } else {
      await updateProject(values);
      resetForm({ values: values });
    }
    setRefresh(true);
  };
  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={basicInfoMap}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, dirty, resetForm }) => (
          <Form className="w-100 d-flex align-items-end flex-column gap-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="name"
                  label="Project Name"
                  fullWidth
                  placeholder="Project Name"
                  error={errors.name && touched.name}
                  helperText={<ErrorMessage name="name" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="project_description"
                  label="Description"
                  fullWidth
                  multiline
                  rows="3"
                  placeholder="Project Description"
                  error={errors.description && touched.description}
                  helperText={<ErrorMessage name="description" />}
                />
              </Grid>
            </Grid>

            <Button disabled={!dirty} type="submit" variant="contained">
              {currentProjectId !== "new" ? "Submit" : "Create New Project"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BasicInfoForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MultiSelectWithChips from "../../../components/fields/MultiSelectChips";
import { ProjectContext } from "../../../context/ProjectContext";
import { genderOptions } from "../../../utils/constants.tsx";
import useStepOperations from "../../../types/Operations.tsx";
import { useCustomReducerHook } from "../../../types/Reducer.tsx";
import { State, Types } from "../../../types/Types.tsx";
// boolean => in case the Odoo model field is empty

interface TargetAudience {
  id: number | null;
  name: string | boolean;
  age_from: number | boolean;
  age_to: number | boolean;
  gender: string | boolean;
  nationality_id: number[] | boolean | null;
  career: string | boolean;
}
const TargetAudiencesForm = () => {
  const { currentProjectId, projectData, countries, setRefresh } =
    useContext(ProjectContext);
  const initialState: State<TargetAudience> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };

  const { state, dispatch } =
    useCustomReducerHook<TargetAudience>(initialState);
  const modelName = "triplet.target.audience";

  const { readAll, handleDelete, dispatchCreateEdit, changeRecord } =
    useStepOperations(currentProjectId, modelName, dispatch, state, Types);

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.target_audiences);
      if (projectData.target_audiences.length === 0) {
        dispatch({
          type: Types.AddAnother,
          payload: {
            values: [],
            newItem: {
              age_from: "",
              age_to: "",
              gender: "",
              nationality_id: [],
              career: "",
            },
          },
        });
      }
    }
  }, [currentProjectId, projectData, readAll]);

  const handleSubmit = (values) => {};

  return (
    <div className="d-flex p-2">
      <Formik
        r
        enableReinitialize
        initialValues={state.data ?? []}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="w-100">
            <Grid container spacing={2}>
              {values.map((audience, index) => (
                <React.Fragment key={index}>
                  {/* <Grid item xs={12}>
                    <Field
                      name={`${index}.name`}
                  
                      as={TextField}
                      label="Name"
                      fullWidth
                      onKeyUp={() => changeRecord(values, index)}
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <Field
                      name={`${index}.age_from`}
                      as={TextField}
                      label="Age From"
                      type="number"
                      fullWidth
                      onKeyUp={() => changeRecord(values, index)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name={`${index}.age_to`}
                      as={TextField}
                      label="Age To"
                      type="number"
                      fullWidth
                      onKeyUp={() => {
                        changeRecord(values, index);
                        changeRecord(values, index);
                      }}
                    />
                  </Grid>
                  {/* Gender */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        name={`${index}.gender`}
                        value={audience.gender}
                        onChange={(e) => {
                          setFieldValue(`${index}.gender`, e.target.value);
                          changeRecord(values, index);
                        }}
                        label="Gender"
                      >
                        {genderOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Nationality */}
                  <Grid item xs={12}>
                    <MultiSelectWithChips
                      label="Nationality"
                      name={`nationality_id`}
                      nestedName={`${index}.nationality_id`}
                      choices={countries}
                      values={values[index]}
                      setFieldValue={setFieldValue}
                      onChange={() => changeRecord(values, index)}
                    />
                  </Grid>
                  {/* Career */}
                  {/* <Grid item xs={12}>
                    <MultiSelectWithChips
                      label="Career"
                      name={`career_id`}
                      nestedName={`${index}.career_id`}
                      choices={careers}
                      values={values[index]}
                      setFieldValue={setFieldValue}
                      onChange={() => changeRecord(values, index)}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Field
                      name={`${index}.career`}
                      as={TextField}
                      label="Career"
                      onKeyUp={() => changeRecord(values, index)}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                  {/* Buttons */}
                  <Grid item xs={12} className="d-flex align-items-center">
                    <Button
                      variant="contained"
                      size="small"
                      disabled={!state.dirtyIndexes.includes(index)}
                      onClick={async () => {
                        await dispatchCreateEdit(index, values);
                        setRefresh(true);
                      }}
                    >
                      Submit
                    </Button>
                    <IconButton
                      onClick={async () => {
                        await handleDelete(audience.id, index);
                        setRefresh(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>

                  {/* Divider */}
                  {index < values.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              <Stack
                marginTop={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: Types.AddAnother,
                      payload: {
                        values: values,
                        newItem: {
                          // name: "",
                          age_from: "",
                          age_to: "",
                          gender: "",
                          nationality_id: [],
                          career: "",
                        },
                      },
                    });
                  }}
                >
                  ADD AUDIENCE
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TargetAudiencesForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Stack,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectContext } from "../../../context/ProjectContext";
import { State, Types } from "../../../types/Types.tsx";
import { useCustomReducerHook } from "../../../types/Reducer.tsx";
import useStepOperations from "../../../types/Operations.tsx";

interface MediaChannel {
  id: number | null;
  name: string;
  category: string | null;
  description: string | null;
}

const MediaChannelsForm = () => {
  const categoryOptions = [
    { id: "website", name: "Website" },
    { id: "social_media", name: "Social Media" },
  ];

  const { currentProjectId, projectData, setRefresh } =
    useContext(ProjectContext);
  const initialState: State<MediaChannel> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };

  const { state, dispatch } = useCustomReducerHook<MediaChannel>(initialState);
  const modelName = "triplet.external.link";

  const { readAll, handleDelete, dispatchCreateEdit, changeRecord } =
    useStepOperations(currentProjectId, modelName, dispatch, state, Types);

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.project_links);
      if (projectData.project_links.length === 0) {
        dispatch({
          type: Types.AddAnother,
          payload: {
            values: [],
            newItem: { name: "", category: "", description: "" },
          },
        });
      }
    }
  }, [currentProjectId, projectData, readAll]);

  const handleSubmit = (values) => {};
  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={state.data ?? []}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          // Inside your Form component
          <Form className="w-100">
            <Grid container spacing={2}>
              {values.length > 0 &&
                values.map((channel, index) => (
                  <React.Fragment key={index}>
                    {/* Name Field */}
                    <Grid item xs={12} md={6}>
                      <Field
                        name={`${index}.name`}
                        as={TextField}
                        label="Social Media Link"
                        fullWidth
                        onKeyUp={() => changeRecord(values, index)}
                      />
                    </Grid>

                    {/* Category Field */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Field
                          name={`${index}.category`}
                          as={Select}
                          label="Category"
                          fullWidth
                          onChange={(e) => {
                            setFieldValue(`${index}.category`, e.target.value);
                            changeRecord(values, index);
                          }}
                        >
                          {categoryOptions.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>

                    {/* Buttons */}
                    <Grid item xs={12} className="d-flex align-items-center">
                      <Button
                        size="small"
                        variant="contained"
                        disabled={!state.dirtyIndexes.includes(index)}
                        onClick={() => {
                          dispatchCreateEdit(index, values);
                          setRefresh(true);
                        }}
                      >
                        {/* <OfflinePinIcon /> */}
                        Submit
                      </Button>
                      <IconButton
                        onClick={() => handleDelete(channel.id, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>

                    {/* Divider */}
                    {index < values.length - 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}

              {/* Add Media Channel Button */}
              <Stack
                marginTop={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: Types.AddAnother,
                      payload: {
                        values: values,
                        newItem: { name: "", category: "", description: "" },
                      },
                    });
                  }}
                >
                  ADD SOCIAL MEDIA LINK
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MediaChannelsForm;

import { useState, useEffect } from "react";
import useApiRequest from "../services/RestClient";

interface DataItem {
  id: number;
  name: string | boolean;
}

const useDataLoader = () => {
  const { searchRead } = useApiRequest();

  const [countries, setCountries] = useState<DataItem[]>([]);
  const [careers, setCareers] = useState<DataItem[]>([]);
  const [templates, setTemplates] = useState<DataItem[]>([]);
  const [languages, setLanguages] = useState<DataItem[]>([]);

  const loadData = async (entity: string): Promise<DataItem[]> => {
    try {
      const response = await searchRead(entity, "[]");
      return response?.records || [];
    } catch (error) {
      console.error(`Error loading data for ${entity}:`, error);
      return [];
    }
  };

  useEffect(() => {
    const loadAllData = async () => {
      try {
        const [
          loadedCountries,
          loadedCareers,
          loadedTemplates,
          loadedLanguages,
        ] = await Promise.all([
          loadData("res.country"),
          loadData("triplet.career"),
          loadData("triplet.general.template"),
          loadData("triplet.language"),
        ]);

        setCountries(loadedCountries);
        setCareers(loadedCareers);
        setTemplates(loadedTemplates);
        setLanguages(loadedLanguages);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { countries, careers, templates, languages };
};

export default useDataLoader;

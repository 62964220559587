import { Button, ButtonGroup, Fab, Tooltip, Typography } from "@mui/material";
import StatusChip from "../../components/chips/StatusChip.tsx";
import logo from "../../assets/logo.png";
import icon1 from "../../assets/icon1.png";

import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";

import LogoutIcon from "@mui/icons-material/Logout";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { HomeContext, HomeProvider } from "../../context/HomeContext.jsx";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { DataGrid } from "@mui/x-data-grid";
import editIcon from "../../assets/Edit.png";
import LoadingKit from "../../components/loading/index";
import {
  ProjectContext,
  ProjectProvider,
} from "../../context/ProjectContext.jsx";
import { StatCard } from "./components/StatCard.jsx";

import { GridActionsCellItem } from "@mui/x-data-grid";

const HomePage = () => {
  const navigate = useNavigate();
  const { data, loading, userData } = useContext(HomeContext);
  const { setCurrentProjectId, setupNewProject } = useContext(ProjectContext);
  if (loading === true) {
    return (
      <div className="d-flex h-100 w-100 align-items-center">
        <LoadingKit />
      </div>
    );
  }

  const handleEdit = (id) => {
    setCurrentProjectId(id);
    navigate(`/project/${id}`);
  };

  const columns = [
    {
      field: "name",
      headerName: "Project Name",
      flex: 2,
    },
    {
      field: "brand_name",
      headerName: "Brand",
      flex: 1,
      renderCell: (params) =>
        params.value === false ? "UNKNOWN" : params.value,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <StatusChip status={params?.row?.status} />,
    },

    {
      field: "job_stage_ids",
      headerName: "Stages",
      align: "center",
      headerAlign: "center",
      flex: 1,
      // valueGetter: (params) => params.value.length,
      renderCell: (params) => (
        <Tooltip title="Go to Stages" placement="top">
          <Button
            sx={{ fontWeight: "bolder" }}
            disabled={params.value.length === 0}
            variant="text"
            onClick={() => navigate(`/project/${params.row.id}/stages`)}
          >
            {params.value.length} Stages
          </Button>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <img
              src={editIcon}
              alt="Edit"
              style={{ height: "24px", width: "24px" }}
            />
          }
          label="Edit"
          onClick={() => handleEdit(params.row.id)}
        />,
      ],
    },
  ];
  return (
    <div className="container-fluid d-flex flex-column w-100 h-100 p-0 gap-4 bg-light">
      <nav className="navbar bg-body-tertiary border-bottom border-2 border-grey ps-4">
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img src={logo} alt="Motion GO" height={40} />
          </a>
          <ButtonGroup variant="text">
            <Button onClick={() => navigate("/profile")}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar />
                <Stack direction="column" alignItems="start">
                  <Typography variant="body2">
                    {userData?.name} | {userData?.email}
                  </Typography>
                </Stack>
              </Stack>
            </Button>
            <Tooltip title="Logout">
              <Button
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
              >
                <LogoutIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div>
      </nav>
      <div className="px-4 d-flex flex-column gap-4">
        <div className="row mt-4 w-100">
          <StatCard
            value={data?.warehouse_count}
            text="Projects"
            color="#400b11"
            icon={
              <img
                src={icon1}
                alt="Edit"
                style={{ height: "50px", width: "50px" }}
              />
            }
            caption={`${data?.length ?? 0} Projects Has Created`}
          />
          <StatCard
            value={data?.total_products}
            text="Running Requests"
            icon={
              <img
                src={icon2}
                alt="Edit"
                style={{ height: "50px", width: "50px" }}
              />
            }
            color="#400b11"
            caption={`${data?.length ?? 0} Projects Has Created`}
          />
          <StatCard
            value={data?.total_orders}
            text="Projects"
            icon={
              <img
                src={icon3}
                alt="Edit"
                style={{ height: "50px", width: "50px" }}
              />
            }
            color="#400b11"
            caption={`${data?.length ?? 0} Projects Has Created`}
          />
          <StatCard
            value={data?.total_invoices_amount}
            text="Reviews"
            icon={
              <img
                src={icon4}
                alt="Edit"
                style={{ height: "50px", width: "50px" }}
              />
            }
            color="#400b11"
            caption={`${data?.length ?? 0} Projects Has Created`}
          />
        </div>
        <Box sx={{ height: "520px", width: "100%" }}>
          <DataGrid
            rows={data ?? []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                color: "#d62639",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cell": {
                fontWeight: "bold",
              },
            }}
            aut
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
        <Box className="d-flex align-items-end justify-content-end w-100">
          <Fab
            variant="extended"
            color="primary"
            onClick={() => {
              setupNewProject();
              navigate("/project/new");
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            New Project
          </Fab>
        </Box>
      </div>
    </div>
  );
};

const HomePageWithProvider = () => (
  <ProjectProvider>
    <HomeProvider>
      <HomePage />
    </HomeProvider>
  </ProjectProvider>
);
export default HomePageWithProvider;

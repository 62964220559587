import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  CircularProgress,
} from "@mui/material";

const MultiSelectWithChips = ({
  label,
  name,
  nestedName,
  choices,
  values,
  setFieldValue,
  onChange,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        label={label}
        multiple
        value={values[name]}
        onChange={(event) => {
          setFieldValue(nestedName ?? name, event.target.value);
          if (typeof onChange === "function") {
            onChange();
          }
        }}
        renderValue={(selected) => (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {selected.map((id) => (
              <Chip
                key={id}
                label={choices.find((choice) => choice.id === id)?.name || ""}
              />
            ))}
          </div>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
            },
          },
        }}
      >
        {choices === null ? (
          <MenuItem value="">
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              <Checkbox checked={values[name].indexOf(choice.id) > -1} />
              {choice.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default MultiSelectWithChips;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  FormControlLabel,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import Button from "@mui/material/Button";
import { TextField, Checkbox } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useApiRequest } from "../../../services/RestClient";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const LoginBox = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { authenticate } = useApiRequest();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    username: "",
    password: "",
    // rememberMe: false,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = (values) => {
    authenticate(values.username, values.password)
      .then((value) => {
        localStorage.setItem("uid", value["uid"]);
        localStorage.setItem("pw", values.password);
        navigate("/");
      })
      .catch((error) => {});
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      paddingX={2}
      boxSizing="border-box"
    >
      <Box maxWidth={500}>
        <Typography
          variant="h5"
          align="center"
          marginBottom="0.76rem"
          color="primary"
          gutterBottom
          fontWeight="bolder"
        >
          Hi, Welcome Back
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="white"
          marginBottom="1rem"
          gutterBottom
        >
          Enter your credentials to continue
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Typography variant="label" color="white" fullWidth>
                {errors.username && touched.username
                  ? "Username *"
                  : "Username"}
              </Typography>
              <Field
                as={TextField}
                name="username"
                fullWidth
                placeholder="Enter your username"
                error={errors.username && touched.username}
                className="pb-4"
              />
              {/* <ErrorMessage
                name="username"
                component="div"
                fullWidth
                className="ot-error-msg w-100"
                align="start"
              /> */}

              <Typography variant="label" color="white" fullWidth>
                {errors.password && touched.password
                  ? "Password *"
                  : "Password"}
              </Typography>
              <Field
                as={TextField}
                name="password"
                placeholder="Enter your password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                error={errors.password && touched.password}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <ErrorMessage
                name="password"
                component="div"
                className="ot-error-msg"
                align="start"
              /> */}

              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                marginY={2}
                fontSize="0.875rem"
              >
                <FormControlLabel
                  sx={{
                    color: "white",
                    "& .MuiCheckbox-root": { color: "white" },
                  }}
                  control={
                    <Field
                      component={(props) => (
                        <Checkbox
                          {...props}
                          color="default"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "primary.main",
                            },
                          }}
                        />
                      )}
                      name="rememberMe"
                    />
                  }
                  label="Remember Me"
                />
                {/* <Link href="/forgot-password" color="primary">
                  Forgot Password?
                </Link> */}
              </Box>

              <Button
                variant="contained"
                size="large"
                type="submit"
                fullWidth
                sx={{
                  marginTop: 2,
                  background: "#d62639",
                  color: "white",
                }}
              >
                Sign In
              </Button>

              <Divider variant="middle" sx={{ margin: "1rem" }} />

              <Link
                href="/register"
                color="primary"
                align="center"
                className="d-flex w-100 justify-content-center"
              >
                Don't have an account?
              </Link>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default LoginBox;

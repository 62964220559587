import { Box, Chip, Container, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useCustomReducerHook } from "../../types/Reducer.tsx";
import { ProjectContext } from "../../context/ProjectContext";
import useStepOperations from "../../types/Operations.tsx";
import { Stage, State, Types } from "../../types/Types.tsx";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useParams } from "react-router-dom";
import { StageFormDialog } from "./components/StageDetailsDialog.tsx";
const StagePage: React.FC = () => {
  const { projectId } = useParams();

  const { currentProjectId, projectData, setCurrentProjectId } =
    useContext(ProjectContext);
  const initialState: State<Stage> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };
  const { state, dispatch } = useCustomReducerHook<Stage>(initialState);
  const modelName = "triplet.job.stage";
  const refreshData = async () => {
    await readAll(projectData.job_stage_ids);
  };
  const { readAll } = useStepOperations(
    currentProjectId,
    modelName,
    dispatch,
    state,
    Types
  );

  useEffect(() => {
    if (projectId && projectId !== currentProjectId) {
      setCurrentProjectId(projectId);
    }
  }, [currentProjectId, projectId, setCurrentProjectId]);

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.job_stage_ids);
    }
  }, [currentProjectId, projectData, readAll, state.data]);
  const columns: GridColDef[] = [
    {
      field: "create_date",
      headerName: "Date",
      minWidth: 200,
    },
    {
      field: "stage_type",
      headerName: "Type",

      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => params.value[1] ?? "Unknown",
    },

    {
      field: "stage_status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        let label = "";
        let color = "default";

        if (params.value === false) {
          label = "New";
        } else if (params.value === "approve") {
          label = "Approved";
          color = "primary";
        } else if (params.value === "review") {
          label = "Review Requested";
          color = "secondary";
        } else {
          label = params.value ? params.value.toString() : "";
        }

        return <Chip label={label.toUpperCase()} color={color} />;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => {
        const stage = params.row as Stage;
        return (
          <StageFormDialog
            stage={transformStageData(stage)}
            onRefresh={refreshData}
          />
        );
      },
    },
  ];
  return (
    <Container maxWidth="lg" className="bg-light p-4">
      <Stack direction="column" gap={2}>
        <Typography variant="h6">{projectData?.name} Stages</Typography>
        <Box height={256}>
          <DataGrid
            rows={state.data ?? []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                color: "#d62639",
                fontWeight: "bolder",
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default StagePage;

function transformStageData(stage: Stage): Stage {
  if (stage.response === false) {
    // If response is false, replace it with an empty string
    return { ...stage, response: "" };
  }
  // Otherwise, return the stage data unchanged
  return stage;
}

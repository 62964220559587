/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState } from "react";
import {
  Button,
  Avatar,
  Grid,
  Container,
  Box,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import homeIcon from "../../assets/home-2.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import HomeIcon from "@mui/icons-material/Home";
import FaceIcon from "@mui/icons-material/Face";
import EditIcon from "@mui/icons-material/Edit";

import { Select, MenuItem } from "@mui/material";

import { Formik, Form, Field } from "formik";
import { ProfileContext, ProfileProvider } from "../../context/ProfileContext";
import LoadingKit from "../../components/loading";
import CustomTextField from "../../components/fields/CustomTextField.tsx";
import { NavLink } from "react-router-dom";

const ProfilePage = () => {
  const { data, loading, updateProfile, countries } =
    useContext(ProfileContext);

  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSubmit = async (values) => {
    setIsEditMode(false);
    await updateProfile(values);
  };

  if (loading) {
    return (
      <div className="d-flex h-100 w-100 align-items-center">
        <LoadingKit />
      </div>
    );
  }

  return (
    <Container maxWidth="xl" className="bg-light m-4 p-4">
      <Formik
        enableReinitialize
        initialValues={data ?? {}}
        onSubmit={handleSubmit}
      >
        {({ submitForm, dirty }) => (
          <Form>
            <Stack direction="column" gap={4}>
              <Breadcrumbs aria-label="breadcrumb">
                <NavLink
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "#424242",
                  }}
                >
                  <img
                    src={homeIcon}
                    alt="Motion GO"
                    height={20}
                    className="me-2"
                  />
                  Home
                </NavLink>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="primary"
                >
                  <FaceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  My Profile
                </Typography>
              </Breadcrumbs>
              <Box
                border="1px solid #d62639"
                borderRadius="0.5rem"
                padding="20px"
                sx={{ background: "white" }}
              >
                <Stack direction="row" justifyContent="space-between" gap={2}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Avatar
                      variant="rounded"
                      src={`data:image/jpeg;base64,${data?.image_1920}`}
                      sx={{ width: "8rem", height: "8rem" }}
                    />
                    <Stack
                      direction="column"
                      gap="4px"
                      alignItems="start"
                      className="w-100"
                    >
                      <Typography variant="h6">{data?.name ?? ""}</Typography>
                      <Typography variant="body1">
                        {data?.email ?? ""}
                      </Typography>
                      <Typography variant="body2">
                        {data?.field_of_business ?? ""}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" justifyContent="space-between">
                    <Chip
                      label={data?.basic_job_ids.length + " REQUESTED JOBS"}
                      sx={{
                        border: "2px solid #d62639",
                        color: "#d62639",
                        fontWeight: "bold",
                        background: "#F8F9FA",
                      }}
                    />
                    {isEditMode ? (
                      <Button
                        onClick={submitForm}
                        disabled={!dirty}
                        variant="contained"
                        sx={{ borderRadius: "14px", fontWeight: "bold" }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        onClick={handleEditClick}
                        variant="outlined"
                        endIcon={<EditIcon />}
                        sx={{ borderRadius: "14px", fontWeight: "bold" }}
                      >
                        Edit
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Box>
              <Box
                border="1px solid #d62639"
                borderRadius="0.5rem"
                padding="20px"
                sx={{ background: "white" }}
              >
                <Stack
                  direction="column"
                  gap={2}
                  alignItems="start"
                  className="w-100"
                >
                  <Typography color="primary" fontWeight="bold">
                    Personal Information
                  </Typography>
                  <CustomTextField
                    name="name"
                    label="name"
                    variant="outlined"
                    size="small"
                    disabled={!isEditMode}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomTextField
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        size="small"
                        disabled={!isEditMode}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        name="phone"
                        label="Phone"
                        variant="outlined"
                        size="small"
                        disabled={!isEditMode}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
              <Box
                border="1px solid #d62639"
                borderRadius="0.5rem"
                padding="20px"
                sx={{ background: "white" }}
              >
                <Stack
                  direction="column"
                  gap={2}
                  alignItems="start"
                  className="w-100"
                >
                  <Typography color="primary" fontWeight="bold">
                    Address
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        as={Select}
                        variant="outlined"
                        fullWidth
                        label="Country"
                        name="country_id"
                        displayEmpty
                        size="small"
                        disabled={!isEditMode}
                      >
                        <MenuItem value="" disabled>
                          Select Country
                        </MenuItem>
                        {countries.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTextField
                        name="city"
                        label="City"
                        variant="outlined"
                        size="small"
                        disabled={!isEditMode}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        name="street"
                        label="Street"
                        variant="outlined"
                        size="small"
                        disabled={!isEditMode}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTextField
                        name="vat"
                        label="TAX ID"
                        variant="outlined"
                        size="small"
                        disabled={!isEditMode}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default () => (
  <ProfileProvider>
    <ProfilePage />
  </ProfileProvider>
);

// useCustomReducerHook.js
import { useReducer } from "react";
import { State, Action, Types } from "./Types.tsx";

function reducer<T>(state: State<T>, action: Action) {
  const type = action.type;
  switch (type) {
    case Types.ChangeRecord:
      if (state.dirtyIndexes.includes(action.payload.id)) {
        return state;
      } else {
        return {
          ...state,
          dirtyIndexes: [...state.dirtyIndexes, action.payload.id],
        };
      }

    case Types.ReadAll:
      return { ...state, data: action.payload, loading: false };
    case Types.SubmitUpdate:
      return {
        ...state,
        dirtyIndexes: state.dirtyIndexes.filter(
          (index) => index !== action.payload.index
        ),
      };

    case Types.CreateRecord:
      if (state.data && action.payload.index < state.data.length) {
        const newData = state.data.map((item, idx) =>
          idx === action.payload.index
            ? {
                ...item,
                id: action.payload.id,
                ...action.payload.value,
              }
            : item
        );
        return {
          ...state,
          data: newData,
          dirtyIndexes: state.dirtyIndexes.filter(
            (index) => index !== action.payload.index
          ),
        };
      } else {
        return state;
      }

    case Types.AddAnother:
      return {
        ...state,
        data: [...(action.payload.values ?? []), action.payload.newItem],
      };

    case Types.DeleteRecord:
      if (state.data && action.payload.index < state.data.length) {
        const newData = [
          ...state.data.slice(0, action.payload.index),
          ...state.data.slice(action.payload.index + 1),
        ];

        return { ...state, data: newData };
      } else {
        return state;
      }

    default:
      return state;
  }
}

export const useCustomReducerHook = <T,>(initialState: State<T>) => {
  const [state, dispatch] = useReducer(reducer<T>, initialState);
  return { state, dispatch };
};

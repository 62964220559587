import React, { useEffect } from "react";
import LoginBox from "./components/LoginBox";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo_white.png";
const LoginPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  });

  const isAuthenticated = () => {
    const uid = localStorage.getItem("uid");
    return !!uid;
  };

  return (
    <div
      className="container-primary-hue d-flex flex-row gap-4 p-4 align-items-center justify-content-evenly vh-100"
      style={{ backgroundColor: "#010803" }}
    >
      <LoginBox />
      <img src={logo} height={100} alt="motion_go" />
    </div>
  );
};

export default LoginPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Stack,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { ProjectContext } from "../../../context/ProjectContext.jsx";
import { State, Types } from "../../../types/Types.tsx";
import { useCustomReducerHook } from "../../../types/Reducer.tsx";
import useCompetitorOperations from "../../../types/Operations.tsx";

interface Competitor {
  id: number | null;
  name: string;
  category: string | null;
}

const categoryOptions = [
  { id: "website", name: "Website" },
  { id: "social_media", name: "Social Media" },
];

const SimilarCompetitorsForm: React.FC = () => {
  const { currentProjectId, projectData, setRefresh } =
    useContext(ProjectContext);
  const initialState: State<Competitor> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };

  const { state, dispatch } = useCustomReducerHook<Competitor>(initialState);
  const modelName = "triplet.competitor";

  const { readAll, handleDelete, dispatchCreateEdit, changeRecord } =
    useCompetitorOperations(
      currentProjectId,
      modelName,
      dispatch,
      state,
      Types
    );

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.project_competitor);
      if (projectData.project_competitor.length === 0) {
        dispatch({
          type: Types.AddAnother,
          payload: {
            values: [],
            newItem: { name: "", category: "" },
          },
        });
      }
    }
  }, [currentProjectId, projectData, readAll]);

  const handleSubmit = (values) => {};

  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={state.data ?? []}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="w-100">
            <Grid container spacing={2}>
              {values?.map((competitor, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name={`${index}.name`}
                      as={TextField}
                      label="Competitor"
                      fullWidth
                      onKeyUp={() => changeRecord(values, index)}
                      // Change or remove onKeyUp handler based on your requirements
                    />
                  </Grid>
                  {/* Category Field */}
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Field
                        name={`${index}.category`}
                        as={Select}
                        label="Category"
                        fullWidth
                        onChange={(e) => {
                          setFieldValue(`${index}.category`, e.target.value);
                          changeRecord(values, index);
                        }}
                      >
                        {categoryOptions.map((option) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="d-flex align-items-center">
                    <Button
                      variant="contained"
                      size="small"
                      disabled={!state.dirtyIndexes.includes(index)}
                      onClick={async () => {
                        await dispatchCreateEdit(index, values);
                        setRefresh(true);
                      }}
                    >
                      Submit
                    </Button>
                    <IconButton
                      onClick={async () => {
                        await handleDelete(competitor.id, index);
                        setRefresh(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
              <Stack
                marginTop={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    // Adjust based on how you want to handle adding a new competitor
                    dispatch({
                      type: Types.AddAnother,
                      payload: {
                        values: values,
                        newItem: { name: "", category: "" },
                      },
                    });
                  }}
                >
                  ADD COMPETITOR
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SimilarCompetitorsForm;

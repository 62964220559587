import React from "react";
import { Chip } from "@mui/material";

const statusColors = {
  draft: "default",
  submitted: "primary",
  in_progress: "secondary",
  under_review: "warning",
  done: "success",
  cancelled: "error",
};

const StatusChip = ({ status }) => {
  status = status || "draft";
  const chipColor = statusColors[status.toLowerCase()] || "default";

  return <Chip label={status.toUpperCase()} color={chipColor} />;
};

export default StatusChip;

import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { Button } from "@mui/material";
import * as Yup from "yup";
import MultiSelectWithChips from "../../../components/fields/MultiSelectChips";
import { ProjectContext } from "../../../context/ProjectContext";

const LanguagesForm = () => {
  const {
    currentProjectId,
    languages,
    updateProject,
    languagesMap,
    setRefresh,
  } = useContext(ProjectContext);

  const validationSchema = Yup.object({
    language_ids: Yup.array(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    await updateProject(values);
    resetForm({ values: values });
    setRefresh(true);
  };

  return (
    <div className="d-flex p-2">
      <Formik
        enableReinitialize
        initialValues={languagesMap}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, dirty, values, setFieldValue, resetForm }) => (
          <Form className="w-100 d-flex align-items-end flex-column gap-3">
            <MultiSelectWithChips
              label="Languages"
              name="language_ids"
              choices={languages}
              values={values}
              setFieldValue={setFieldValue}
              nestedName={null}
              onChange={null}
            />

            <Button disabled={!dirty} type="submit" variant="contained">
              {currentProjectId === "new" ? "Add Languages" : "Submit"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LanguagesForm;

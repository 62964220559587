/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect } from "react";
import { ProjectContext } from "../../../context/ProjectContext";
import useStepOperations from "../../../types/Operations.tsx";
import { useCustomReducerHook } from "../../../types/Reducer.tsx";
import { State, Types } from "../../../types/Types.tsx";
import { Formik, Form, Field } from "formik";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  TextField,
  Button,
  FormGroup,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import {
  genderOptions,
  videoDurationOptions,
} from "../../../utils/constants.tsx";
// import {
//   convertToBase64,
//   isAttachmentValid,
//   openBase64InNewTab,
// } from "../../../utils/helpers.tsx";
interface TechnicalCharacteristic {
  id: number | null;
  video_duration: string | boolean;
  voice_over: boolean;
  voice_over_description: string;
  sound_gender: string;
  custom_video_duration: number | boolean;
  sound_age_from: number | boolean;
  sound_age_to: number | boolean;
  visual_identity: string | boolean;
  visual_identity_attachment: string | null;
  animated_identity: string | boolean;
  animated_identity_attachment: string | null;
}

const TechnicalCharacteristicsForm: React.FC = () => {
  const { currentProjectId, projectData, setRefresh } =
    useContext(ProjectContext);
  const initialState: State<TechnicalCharacteristic> = {
    data: null,
    error: null,
    loading: false,
    dirtyIndexes: [],
  };

  const { state, dispatch } =
    useCustomReducerHook<TechnicalCharacteristic>(initialState);
  const modelName = "triplet.video.technical.characteristics";

  const { readAll, handleDelete, dispatchCreateEdit, changeRecord } =
    useStepOperations(currentProjectId, modelName, dispatch, state, Types);

  useEffect(() => {
    if (
      (currentProjectId !== null || currentProjectId !== "new") &&
      projectData !== null &&
      state.data === null
    ) {
      readAll(projectData.technical_characteristics);

      if (projectData.technical_characteristics.length === 0) {
        dispatch({
          type: Types.AddAnother,
          payload: {
            values: [],
            newItem: {
              video_duration: "",
              voice_over: false,
              voice_over_description: "",
              sound_gender: "",
              custom_video_duration: "",
              sound_age_from: "",
              sound_age_to: "",
              visual_identity: "",
              visual_identity_attachment: "",
              animated_identity: "",
              animated_identity_attachment: "",
            },
          },
        });
      }
    }
  }, [currentProjectId, projectData, readAll]);

  const handleSubmit = (values) => {};

  return (
    <div className="d-flex p-2">
      <Formik
        r
        enableReinitialize
        initialValues={state.data ?? []}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="w-100">
            <Grid container spacing={2}>
              {values.map((space, index) => (
                <Fragment key={index}>
                  {/* Video Duration */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Video Duration</InputLabel>
                      <Select
                        name={`${index}.video_duration`}
                        value={space.video_duration}
                        onChange={(e) => {
                          setFieldValue(
                            `${index}.video_duration`,
                            e.target.value
                          );
                          changeRecord(values, index);
                        }}
                        label="Gender"
                      >
                        {videoDurationOptions.map((option) => (
                          <MenuItem key={option.label} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* TODO: set the value of customer video duration to false when the the choice is not other */}
                  {values[index].video_duration === "other" ? (
                    <Grid item xs={12}>
                      <Field
                        name={`${index}.custom_video_duration`}
                        as={TextField}
                        label="Custom Video Duration"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Seconds
                            </InputAdornment>
                          ),
                        }}
                        onKeyUp={() => changeRecord(values, index)}
                      />
                    </Grid>
                  ) : null}
                  {/* Voice Over Checkbox */}
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Field
                            name={`${index}.voice_over`}
                            type="checkbox"
                            as={Checkbox}
                          />
                        }
                        label="Voice Over"
                      />
                    </FormGroup>
                  </Grid>
                  {values[index].voice_over === true ? (
                    <Fragment>
                      {/*Sound Gender */}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Sound Gender</InputLabel>
                          <Select
                            name={`${index}.sound_gender`}
                            value={space.sound_gender}
                            onChange={(e) => {
                              setFieldValue(
                                `${index}.sound_gender`,
                                e.target.value
                              );
                              changeRecord(values, index);
                            }}
                          >
                            {genderOptions.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name={`${index}.sound_age_from`}
                          as={TextField}
                          label="Sound Age From"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Years
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          onKeyUp={() => changeRecord(values, index)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name={`${index}.sound_age_to`}
                          as={TextField}
                          label="Sound Age To"
                          type="number"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Years
                              </InputAdornment>
                            ),
                          }}
                          onKeyUp={() => {
                            changeRecord(values, index);
                            changeRecord(values, index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={`${index}.voice_over_description`}
                          as={TextField}
                          label="Description"
                          placeholder="Voice Over Description..."
                          onKeyUp={() => changeRecord(values, index)}
                          fullWidth
                          multiline
                          rows={3}
                        />
                      </Grid>
                    </Fragment>
                  ) : null}

                  {/* VISUAL IDENTITY */}
                  {/* <Typography variant="subtitle1" margin="20px 10px 0 20px">
                    Do you have an integrated visual identity? Logos, colors or
                    fonts? To comply with the video
                  </Typography>

                  <Grid item xs={12}>
                    <Field
                      name={`${index}.visual_identity`}
                      as={TextField}
                      label="Visual Identity Description"
                      placeholder="If you have any Logos, colors or fonts, please mention them..."
                      onKeyUp={() => changeRecord(values, index)}
                  
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className="position-relative">
                      {!isAttachmentValid(
                        values[index].visual_identity_attachment
                      ) ? (
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          startIcon={<BsFolderPlus />}
                        >
                          Upload Attachment
                        </Button>
                      ) : (
                        <Button variant="contained" fullWidth size="large">
                          Change Attachment
                        </Button>
                      )}
                      <input
                        className="d-none"
                        type="file"
                        id={`${index}.visual_identity`}
                        onChange={async (event) => {
                          if (
                            event.currentTarget.files &&
                            event.currentTarget.files[0]
                          ) {
                            try {
                              const base64String = await convertToBase64(
                                event.currentTarget.files[0]
                              );

                              setFieldValue(
                                `${index}.visual_identity_attachment`,
                                base64String
                              );
                              changeRecord(values, index);
                            } catch (error) {}
                          }
                        }}
                      />

                      <label
                        htmlFor={`${index}.visual_identity`}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </Grid> */}
                  {/* ANIMATED IDENTITY */}
                  {/* <Typography variant="subtitle1" margin="20px 10px 0 20px">
                    Do you have an animated identity for your logo?
                  </Typography>

                  <Grid item xs={12}>
                    <Field
                      name={`${index}.animated_identity`}
                      as={TextField}
                      label="Animated Identity Description"
                      onKeyUp={() => changeRecord(values, index)}
                  
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className="position-relative">
                      {!isAttachmentValid(
                        values[index].animated_identity_attachment
                      ) ? (
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          startIcon={<BsFolderPlus />}
                        >
                          Upload Attachment
                        </Button>
                      ) : (
                        <Button variant="contained" fullWidth size="large">
                          Change Attachment
                        </Button>
                      )}
                      <input
                        className="d-none"
                        type="file"
                        id={`${index}.animated_identity`}
                        onChange={async (event) => {
                          if (
                            event.currentTarget.files &&
                            event.currentTarget.files[0]
                          ) {
                            try {
                              const base64String = await convertToBase64(
                                event.currentTarget.files[0]
                              );

                              setFieldValue(
                                `${index}.animated_identity_attachment`,
                                base64String
                              );
                              changeRecord(values, index);
                            } catch (error) {}
                          }
                        }}
                      />

                      <label
                        htmlFor={`${index}.animated_identity`}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </Grid> */}

                  {/* Buttons */}
                  <Grid item xs={12} className="d-flex align-items-center">
                    <Button
                      variant="contained"
                      size="small"
                      disabled={!state.dirtyIndexes.includes(index)}
                      onClick={async () => {
                        await dispatchCreateEdit(index, values);
                        setRefresh(true);
                      }}
                    >
                      Submit
                    </Button>
                    {/* <IconButton
                      disabled={
                        values[index].visual_identity_attachment === null ||
                        values[index].visual_identity_attachment === false ||
                        values[index].visual_identity_attachment === ""
                      }
                      onClick={() =>
                        openBase64InNewTab(
                          values[index].visual_identity_attachment
                        )
                      }
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        values[index].animated_identity_attachment === null ||
                        values[index].animated_identity_attachment === false ||
                        values[index].animated_identity_attachment === ""
                      }
                      onClick={() =>
                        openBase64InNewTab(
                          values[index].animated_identity_attachment
                        )
                      }
                    >
                      <RemoveRedEyeIcon />
                    </IconButton> */}
                    <IconButton
                      onClick={async () => {
                        await handleDelete(space.id, index);
                        setRefresh(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  {/* Divider */}
                  {index < values.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </Fragment>
              ))}
              <Stack
                marginTop={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: Types.AddAnother,
                      payload: {
                        values: values,
                        newItem: {
                          video_duration: "",
                          voice_over: false,
                          voice_over_description: "",
                          sound_gender: "",
                          custom_video_duration: "",
                          sound_age_from: "",
                          sound_age_to: "",
                          visual_identity: "",
                          visual_identity_attachment: "",
                          animated_identity: "",
                          animated_identity_attachment: "",
                        },
                      },
                    });
                  }}
                >
                  ADD Space
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default TechnicalCharacteristicsForm;

import React, { createContext, useState, useEffect } from "react";
import useApiRequest from "../services/RestClient.jsx";
import useDataLoader from "../hooks/ConfigHook.tsx";
import { useNavigate } from "react-router-dom";
export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const navigate = useNavigate();
  const { searchRead, create, update, deleteRecord } = useApiRequest();
  const { countries, careers, templates, languages } = useDataLoader();
  const [projectData, setProjectData] = useState(null);

  const [basicInfoMap, setBasicInfoMap] = useState({
    name: "",
    project_description: "",
  });

  const [projectDetailsMap, setProjectDetailsMap] = useState({
    brand_name: "",
    brand_website: "",
    brand_description: "",
    country_ids: [],
    company_location: "",
  });

  const [languagesMap, setLanguagesMap] = useState({
    language_ids: [],
  });

  const [templatesMap, setTemplateMaps] = useState({
    selectedTemplateIds: [],
  });

  const [loading, setLoading] = useState(true);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    console.log("refresh");
    if (refresh === true) {
      loadProject().then(() => {
        setRefresh(false);
      });
    }
  }, [refresh]);
  const setupNewProject = () => {
    setCurrentProjectId(null);
    setProjectData(null);

    setBasicInfoMap({ name: "", project_description: "" });
    setProjectDetailsMap({
      brand_name: "",
      brand_website: "",
      brand_description: "",
      country_ids: [],
      company_location: "",
    });
    setLanguagesMap({
      language_ids: [],
    });

    setTemplateMaps({
      selectedTemplateIds: [],
    });
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (currentProjectId !== null && currentProjectId !== "new") {
        loadProject().then((_) => setLoading(false));
      }
      if (currentProjectId === "new") {
        setLoading(false);
      }
    }, 0);
  }, [currentProjectId]);

  const loadProject = async () => {
    await searchRead(
      "triplet.job.video_design",
      `[[['id', '=', ${currentProjectId}]]]`
    ).then(async (values) => {
      if (values?.records?.length > 0) {
        const data = values?.records[0];
        setProjectData(data);
        setBasicInfoMap({
          name: data?.name ?? "",
          project_description: data?.project_description ?? "",
        });
        setProjectDetailsMap({
          brand_name: data?.brand_name !== false ? data?.brand_name ?? "" : "",
          brand_website:
            data?.brand_website !== false ? data?.brand_website ?? "" : "",
          brand_description:
            data?.brand_description !== false
              ? data?.brand_description ?? ""
              : "",
          country_ids: data?.country_ids ?? [],
          company_location:
            data?.company_location !== false
              ? data?.company_location ?? ""
              : "",
        });
        setLanguagesMap({ language_ids: data?.language_ids ?? [] });
        setTemplateMaps({ selectedTemplateIds: data?.similar_templates ?? [] });
      }
    });
  };

  const createProject = async (values) => {
    setLoading(true);
    values["partner_id"] = localStorage.getItem("user_id");
    await create(
      "triplet.job.video_design",
      values,
      "Project Created Successfully"
    ).then((values) => {
      setCurrentProjectId(values.id);
      navigate(`/project/${values.id}`, { replace: true });
    });
  };

  const updateProject = async (values) => {
    await update(
      "triplet.job.video_design",
      currentProjectId,
      values,

      "Project Updated Successfully"
    ).then((values) => {
      console.log(values);
    });
  };

  const deleteRec = async (modelName, recordId) => {
    await deleteRecord(modelName, recordId, "Deleted Successfully");
  };

  const updateRecord = async (modelName, recordId, data) => {
    await update(modelName, recordId, data).then((values) => {
      console.log(values);
    });
  };

  const updateStatus = async () => {
    try {
      await updateProject({ status: "submitted" });
      setProjectData({ ...projectData, status: "submitted" });
    } catch (e) {}
  };

  return (
    <ProjectContext.Provider
      value={{
        currentProjectId,
        loading,
        createProject,
        updateProject,
        countries,
        languages,

        projectData,
        templates,

        careers,
        setRefresh,

        setCurrentProjectId,

        setupNewProject,

        basicInfoMap,
        projectDetailsMap,
        languagesMap,
        templatesMap,

        deleteRec,
        updateRecord,
        updateStatus,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  FormControlLabel,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import Button from "@mui/material/Button";
import { TextField, Checkbox } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import * as Yup from "yup";
import { useApiRequest } from "../../../services/RestClient";
import { useNavigate } from "react-router-dom";

const SignUpBox = () => {
  const navigate = useNavigate();
  const { createPortalUser } = useApiRequest();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email Address is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = (values) => {
    const userDetails = {
      login: values?.email,
      password: values?.password,
      name: values?.username,
    };

    createPortalUser(userDetails)
      .then((response) => {
        localStorage.setItem("uid", response?.user_id);
        localStorage.setItem("pw", values?.password);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error creating portal user: ", error);
      });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      paddingX={2}
      boxSizing="border-box"
    >
      <Box maxWidth={500}>
        <Typography
          variant="h5"
          align="center"
          marginBottom="0.76rem"
          gutterBottom
          color="primary"
          fontWeight="bolder"
        >
          Welcome!
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom="1rem"
          gutterBottom
          align="center"
          color="white"
        >
          Create, inspire, and bring your vision to life
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Typography variant="label" color="white" fullWidth>
                {errors.email && touched.email ? "Email *" : "Email"}
              </Typography>
              <Field
                as={TextField}
                name="email"
                type="email"
                fullWidth
                placeholder="Your email address"
                error={errors.email && touched.email}
                className="pb-4"
              />
              <Typography variant="label" color="white" fullWidth>
                {errors.username && touched.username
                  ? "Username *"
                  : "Username"}
              </Typography>
              <Field
                as={TextField}
                name="username"
                placeholder="Your username"
                fullWidth
                className="pb-4"
                error={errors.username && touched.username}
              />
              <Typography variant="label" color="white" fullWidth>
                {errors.password && touched.password
                  ? "Password *"
                  : "Password"}
              </Typography>
              <Field
                as={TextField}
                name="password"
                className="pb-4"
                autoComplete="current-password"
                placeholder="Your password"
                type={showPassword ? "text" : "password"}
                error={errors.password && touched.password}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="label" color="white" fullWidth>
                {errors.confirmPassword && touched.confirmPassword
                  ? "Confirm Password *"
                  : "Confirm Password"}
              </Typography>
              <Field
                as={TextField}
                name="confirmPassword"
                className="pb-4"
                placeholder="Your password again"
                type={showPassword ? "text" : "password"}
                fullWidth
                error={errors.confirmPassword && touched.confirmPassword}
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginY={2}
                fontSize="0.875rem"
              >
                <FormControlLabel
                  sx={{
                    color: "white",
                    "& .MuiCheckbox-root": { color: "white" },
                  }}
                  control={
                    <Field
                      component={(props) => (
                        <Checkbox
                          {...props}
                          color="default"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "primary.main",
                            },
                          }}
                        />
                      )}
                      name="rememberMe"
                    />
                  }
                  label="Remember Me"
                />
              </Box>
              <Button
                variant="contained"
                size="large"
                type="submit"
                fullWidth
                sx={{
                  marginTop: 2,
                  background: "#d62639",
                  color: "white",
                }}
              >
                Sign Up
              </Button>
              <Divider variant="middle" sx={{ margin: "1rem" }} />
              <Link
                href="/login"
                color="primary"
                className="d-flex w-100 justify-content-center"
              >
                Already have an account?
              </Link>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default SignUpBox;
